<template>
  <div class="main">
    <van-popup v-model="popupShow" :close-on-click-overlay="false">
      <div class="popup_class">
        <div class="title">欢迎使用阿龙和小鱼</div>
        <p v-if="isLoadedAllVideo" class="tips1">加载完毕</p>
        <!-- <p v-else class="tips1">资源加载中，请稍后...</p> -->
        <van-progress v-else class="progress" :percentage="load_percent" stroke-width="8" color="#ee2e2e" track-color="#dddddd" :show-pivot="false" />
        <div v-if="isLoadedAllVideo" class="btn" @click="popupNo">确认</div>
        <p v-else class="tips2">加载中...</p>
      </div>
    </van-popup>
    <audio id="audioDiv" ref="audioDiv" controls style="display: none;"></audio>
    <audio id="audioDivIOS" ref="audioDivIOS" controls style="display: none;"></audio>
    <div class="header">
      <div v-show="headType==1" class="header_box1">
        <van-swipe class="my-swipe" indicator-color="#ee2e2e" :initial-swipe="swipeIndex" :touchable="touchable" @change="onChange">
          <van-swipe-item>
            <div class="video_box">
              <!-- v-show="isIOS&&csh" -->
              <img class="gifImg video_item_box" :class="isIOS&&csh?'zindex110':''" src="../../static/video/woman1.gif" alt="">
              <!-- v-show="!(isIOS&&csh)" -->
              <!-- <div> -->
              <!-- v-show="video_show" -->
              <video id="video-1-1" class="video_item_box" :class="!(isIOS&&csh) && video_show ? 'zindex110' : ''" src="../../static/video/video_1_1.mp4" loop muted playsinline x5-playsInline></video>
              <!-- v-show="!video_show" -->
              <!-- <div> -->
              <!-- v-show="!firstAiVideo"  -->
              <video id="video-1-2" class="video_item_box" :class="!(isIOS&&csh)&&!video_show && !firstAiVideo ? 'zindex110' : ''" src="../../static/video/video_1_2.mp4" loop muted playsinline x5-playsInline></video>
              <!-- v-show="firstAiVideo" -->
              <video id="video-1-3" class="video_item_box" :class="!(isIOS&&csh)&&!video_show && firstAiVideo ? 'zindex110' : ''" src="../../static/video/video_1_3.mp4" loop muted playsinline x5-playsInline></video>
            <!-- </div> -->
            <!-- </div> -->
            </div>
          </van-swipe-item>
          <van-swipe-item>
            <!-- v-show="" -->
            <div class="video_box">
              <img class="gifImg video_item_box" :class="isIOS&&csh ? 'zindex110' : ''" src="../../static/video/man1.gif" alt="">
              <!-- v-show="!(isIOS&&csh)" -->
              <!-- <div > -->
              <!-- v-show="video_show" -->
              <video id="video-2-1" :class="!(isIOS&&csh) && video_show ? 'zindex110' : ''" class="video_item_box" src="../../static/video/video_2_1.mp4" loop muted playsinline x5-playsInline></video>
              <!-- v-show="!video_show" -->
              <!-- <div > -->
              <!-- v-show="!firstAiVideo" -->
              <video id="video-2-2" class="video_item_box" :class="!(isIOS&&csh)&&!video_show && !firstAiVideo ? 'zindex110' : ''" src="../../static/video/video_2_2.mp4" loop muted playsinline x5-playsInline></video>
              <!-- v-show="firstAiVideo" -->
              <video id="video-2-3" class="video_item_box" :class="!(isIOS&&csh)&&!video_show && firstAiVideo ? 'zindex110' : ''" src="../../static/video/video_2_3.mp4" loop muted playsinline x5-playsInline></video>
              <!-- </div> -->
              <!-- </div> -->
            </div>
          </van-swipe-item>
          <!-- <van-swipe-item>
            <img v-show="isIOS&&csh" class="gifImg" src="../../static/video/woman2.gif" alt="">
            <div v-show="!(isIOS&&csh)">
              <video v-show="video_show" id="video-3-1" src="../../static/video/video_3_1.mp4" loop muted playsinline x5-playsInline></video>
              <div v-show="!video_show">
                <video v-show="!firstAiVideo" id="video-3-2" src="../../static/video/video_3_2.mp4" loop muted playsinline x5-playsInline></video>
                <video v-show="firstAiVideo" id="video-3-3" src="../../static/video/video_3_3.mp4" loop muted playsinline x5-playsInline></video>
              </div>
            </div>
          </van-swipe-item> -->
        </van-swipe>
        <div class="btn" @click="sq">
          <span v-if="touchable">收起</span>
          <span v-else>收 起</span>
          <van-icon name="arrow-up" />
        </div>
      </div>
      <div v-show="headType==2" class="header_box2">
        <div class="back"></div>
        <img :src="sq_img[swipeIndex2]" alt="">
        <div class="btn" @click="zk">
          <span>展开</span>
          <van-icon name="arrow-down" />
        </div>
      </div>
    </div>
    <div class="content" :class="headType==1?'paddingheight1':'paddingheight2'">
      <div v-for="(item,index) in list" :key="index">
        <div :class="item.type == 1 ? 'left' : 'right'">
          <img v-if="item.type == 1" :src="sq_img[swipeIndex2]" alt="">
          <img v-else :src="avator" alt="" onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'">
          <div class="text">
            <div v-if="item.type == 2">
              <div>
                <div class="text1">{{ item.q_source }}</div>
                <!-- <div class="text2">{{ item.q_en }}</div> -->
              </div>
            </div>
            <div v-else>
              <div class="text1">{{ item.a_en }}</div>
              <div class="text2">{{ item.a_cn }}</div>
            </div>

            <div v-if="item.type == 1" class="sound2" @click="autoPlay(item,index)">
              <img v-if="!item.isPlay" src="https://newoss.zhulong.com/forum/202405/15/11/1715745493590909.png" alt="">
              <img v-else src="https://newoss.zhulong.com/forum/202405/15/12/1715746097385106.png" alt="">
            </div>
            <div v-else>
              <div class="caozuo">
                <div class="caozuo_left">
                  <span class="cz_left_span">
                    <!-- 句子优化 -->
                    <span class="cz_left_span1" @click="aiTextClick(item)"><img src="https://newoss.zhulong.com/forum/202407/10/46/1427465silvtfsgtqesvta.png" alt=""></span>
                  </span>
                  <!-- AI -->
                  <span class="cz_left_span2" @click="aiAudioClick(item)"><img src="https://newoss.zhulong.com/forum/202407/10/7/142807dwocxm4jdra75vbj.png" alt=""></span>
                </div>
                <div v-if="!item.is_history" class="sound1" @click="autoPlay(item,index)">
                  <img v-if="!item.isPlay" src="https://newoss.zhulong.com/forum/202405/15/11/1715745493590909.png" alt="">
                  <img v-else src="https://newoss.zhulong.com/forum/202405/15/12/1715746097385106.png" alt="">
                </div>
              </div>
              <div v-show="item.ai_text" class="text1" style="margin: 3px 0 0;">{{ item.q_en }}</div>
            </div>
          </div>
        </div>
        <!-- <div style="color: red;">
          {{ websocket_audio2txt_result_msg }}
        </div> -->
      </div>
      <van-loading v-if="loading" class="pageloading" />
    </div>
    <div class="bottom">
      <div v-if="!speakIng" :class="isLongPress? 'btn_gray' : 'btn_red'" :style="{ pointerEvents: !speakIng ? 'auto' : 'none' }" @touchstart.prevent="!loading&&touchStartRecordBtn($event)" @touchend.prevent="!loading&&touchEndRecordBtn($event)">
        <span v-show="!ailoading">{{ isLongPress ? '点击开始录音' : '按下说话' }}</span>
        <div v-show="ailoading" class="spinner"></div>
      </div>
      <div v-else class="btn2">
        <div class="clear" :style="{ pointerEvents: speakIng ? 'auto' : 'none' }" @touchstart.prevent="stopRecordAudio">
          <img src="https://newoss.zhulong.com/forum/202405/15/14/1715755888206079.png" alt="">
        </div>
        <div class="">
          <div class="timing">{{ timeSpan }}</div>
          <div id="timing"> </div>
          <div class="loading">
            <div class="load"></div>
            <div class="load"></div>
            <div class="load"></div>
            <div class="load"></div>
          </div>
        </div>
        <div class="send" @click="send">
          <img src="https://newoss.zhulong.com/forum/202405/15/14/1715756134139292.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// VConsole will be exported to `window.VConsole` by default.
// var vConsole = new VConsole()

import Cookies from 'js-cookie'
// import Recorder from 'js-audio-recorder'
import Recorder from './recorder'
import audioplay from './audio'
import { getAiEnToken, chatAiEnglish, speechSynthesizer, gethistorymsg_wav } from '@/api/chatGPT'
import WechatState from '@/utils/isWechatState.js'
import wxsdk from 'weixin-js-sdk'

export default {
  components: {
    audioplay
  },
  data() {
    return {
      load_percent: 0,
      load_timer: null,
      sq_img: [
        'https://newoss.zhulong.com/forum/202407/10/27/182327uav5q1zlocxiggh0.png',
        'https://newoss.zhulong.com/forum/202407/10/45/182345ffunym2uodjgv9xq.png',
        'https://newoss.zhulong.com/forum/202406/24/18/1719224705258965.png'
      ],
      ailoading: false, // 按下说话的加载状态
      popupShow: true, // 初始话弹层
      avator: '', // 用户头像
      touchable: true, // 轮播图是否可以滑动
      loading: false,
      headType: 1,
      swipeIndex: '',
      swipeIndex2: 0,
      recorder: new Recorder({
        sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
        sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
        numChannels: 1, // 声道，支持 1 或 2， 默认是1
        compiling: true // (0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
      }),
      speakIng: false,
      // 介绍欢迎语
      introl_woman_en: `Hi, I'm Xiaoyu, your AI English learning assistant. Nice to meet you!`, // 欢迎语女-英文
      introl_woman_cn: '你好，我是小鱼儿，你的人工智能英语学习助手。很高兴认识你！', // 欢迎语女-中文
      introl_man_en: `Hi, I'm Long, your AI English learning assistant. Nice to meet you!`, // 欢迎语男-英文
      introl_man_cn: '你好，我是阿龙，你的人工智能英语学习助手。很高兴认识你！', // 欢迎语男-中文
      // cn: '你好我是你的人工智能英语学习助理。很高兴认识你！',
      // en: `Hi! I'm your AI English learning assistant. Nice to meet you!`,
      // 列表数据
      list: [
        {
          type: 1,
          isPlay: false,
          a_cn: '',
          a_en: '',
          q_cn: '',
          q_source: '',
          q_en: '',
          wav: '',
          ai_text: false
        },
        {
          type: 1,
          isPlay: false,
          a_cn: '你可以用英文或者中文和我对话。如果需要调节语速，你可以对我说：降低或提高语速。',
          a_en: `You can talk to me in English or Chinese. If you need to adjust the speaking speed, you can tell me: "lower the speed" or "increase the speed."`,
          q_cn: '',
          q_source: '',
          q_en: '',
          wav: '',
          ai_text: false
        },
        {
          type: 1,
          isPlay: false,
          a_cn: '你也可以为我设定不同的英语水平：小学英语，中学英语，雅思英语等。比如，你可以对我说：现在你是一个雅思考官，请对我进行提问。',
          a_en: `You can set different English proficiency levels for me: primary school English, secondary school English, IELTS English, etc. You can also say to me: Now you are an IELTS examiner, please ask me some questions.`,
          q_cn: '',
          q_en: '',
          q_source: '',
          wav: '',
          ai_text: false
        }
      ],
      myAuto: new Audio(),
      myAutoIndex: '',

      token: '',
      appkey: '',
      interval: null,
      interval2: null,
      websocket: null,
      timeSpan: '',
      websocket_audio2txt_time: 0, // websocket 语音转文本  一句话收集完毕的时间，用于判断间隔
      websocket_audio2txt_result_msg: '',
      websocket_audio2txt_complete_b: false, // websocket 语音转文本  是否完成   true:完毕  false:未完毕

      bufferend: false,
      baseUrl: '',
      url: '',
      eventSource: null,
      take: '',
      headerTemplate: {
        appkey: '',
        message_id: '',
        task_id: '',
        namespace: 'FlowingSpeechSynthesizer',
        name: 'StartSynthesis'
      },
      websocket2: null,
      initPromise: null,
      connectPromise: null,
      mediaPromise: null,
      played: new Event('played'),
      sourceBuffer: null,
      meAudiomp3: '', // 自己的录音

      recordedChunks: [], // 用于存储每次调用 getNextData() 得到的数据
      combinedAudioData: null, // 最终合并的音频数据，初始化为 null

      audioElement: '',
      videoElements: [],
      audioContext: null,
      analyser: null,
      source: null,
      dataArray: null,
      currentPhoneme: 'a',
      video_show: true,
      subtitlesList: [],
      context: '',
      // voice参数值
      voice_type: 'lydia',

      isIOS: this.checkIfIOS(),
      csh: true,
      firstAiVideo: true,
      isLoadedAllVideo: false,
      // 中间变量 控制isLoadedAllVideo=true
      loadTime1: false,
      loadTime2: false,
      // 语速
      speach_rate: 0,
      // 获取历史记录
      is_getted_history: false,
      // 登录限制
      lougout_use_limit: 10, // 未登录：使用限制次数
      isLogin: false,
      introl_def_count: 0, // 默认介绍语个数，登录限制用
      // 等级、
      lg_type: '通用英语', // 默认 通用英语
      // 语言：中 、英
      lang_type: 'en', // zh、en，默认：en
      // 底部按钮长按
      isLongPress: false,
      longpressTimer: null
    }
  },
  mounted() {
    /*
    1、video_1_1：1 等待的
    2、video_1_2：2 普通说话 对口型的
    3、video_1_3：3 welcom的介绍语的
    */
    document.title = '阿龙和小鱼'
    // 切换第一句介绍语，默认女
    this.setFirstIntrol(this.swipeIndex2)
    // 获取介绍语个数
    this.introl_def_count = this.list.length
    // 获取头像
    if (Cookies.get('uid')) {
      this.isLogin = true
      this.getAvator(Cookies.get('uid'))
    } else {
      this.isLogin = false
      this.avator = 'https://newoss.zhulong.com/tfs/noavatar_big.gif'
    }
    this.getAiEnToken()
    this.setupAudioPlayer()
    this.initWebAudioAPI()
    this.myAutoIndex = 0

    // 历史数据
    this.getHistoryDatas()

    // 获取video dom 标签
    this.$nextTick(() => {
      this.delayGetDoms()
    })
  },
  methods: {
    // 获取video dom 标签
    delayGetDoms() {
      this.startLoadTimer()

      this.videoElements = [
        { 'key1': document.getElementById('video-1-1'), 'key2': document.getElementById('video-1-2'), 'key3': document.getElementById('video-1-3') },
        { 'key1': document.getElementById('video-2-1'), 'key2': document.getElementById('video-2-2'), 'key3': document.getElementById('video-2-3') },
        { 'key1': document.getElementById('video-3-1'), 'key2': document.getElementById('video-3-2'), 'key3': document.getElementById('video-3-3') }
        // 添加其他口型的视频元素
      ]

      // 延迟加载方法1
      setTimeout(() => {
        this.loadTime1 = true
        this.checkAllload()
      }, 3000)

      // 延迟加载方法2
      const _this = this
      window.onload = function() {
        // 页面加载完成后的操作
        console.log('页面加载完成')
        _this.loadTime2 = true
        _this.checkAllload()
      }

      // window.addEventListener('load', function() {
      //   console.log('页面加载完成1')
      // })

      // 采取加载事件监听方法获取dom，目前在ios小程序上有点问题
      // this.loadingAllVideos()

      // 滚动到底部
      this.scorellToBottom()
    },
    startLoadTimer() {
      this.closeLoadTimer()
      this.load_timer = setInterval(this.loadAction, 300)
    },
    closeLoadTimer() {
      clearInterval(this.load_timer)
      this.load_timer = null
    },
    loadAction() {
      const remain = (100 - this.load_percent)
      var add_percent = remain * 0.1
      if (remain < 20) {
        add_percent = remain * 0.02
      }
      this.load_percent += add_percent
    },
    checkAllload() {
      console.log('checkAllload1 = ', this.loadTime1)
      console.log('checkAllload2 = ', this.loadTime2)
      console.log('is_getted_history = ', this.is_getted_history)
      if (this.loadTime1 && this.loadTime2 && this.is_getted_history) {
        this.load_percent = 100
        this.isLoadedAllVideo = true
        this.closeLoadTimer()
      }
    },
    // 弹窗提示的 确定按钮
    popupNo() {
      if (this.isLoadedAllVideo) {
        this.popupShow = false
        if (this.isIOS) {
          // 初始化视频
          this.videoElements[0].key1.play()
          this.videoElements[1].key1.play()
          // this.videoElements[2].key1.play()
          if (this.csh) {
            this.myAuto.play()
          }
        } else {
          this.videoElements[0].key1.play()
          this.videoElements[1].key1.play()
        }
        this.touchable = false
        this.audio_ios_mp3(this.list[0])
      } else {
        console.log('资源加载中~~~')
      }
    },
    // 获取video dom
    loadingAllVideos() {
      // 暂时不用 'video-3-1', 'video-3-2', 'video-3-3'，后面用到的话在此再添加
      this.videoElements = [
        { 'key1': null, 'key2': null, 'key3': null, 'pre_key': 'video-1-' },
        { 'key1': null, 'key2': null, 'key3': null, 'pre_key': 'video-2-' }
      ]
      var _this = this
      var count = 0
      this.videoElements.forEach((obj, idx) => {
        const key1 = obj.pre_key + '1'
        const key2 = obj.pre_key + '2'
        const key3 = obj.pre_key + '3'
        const video1 = document.getElementById(key1)
        console.log('key1 key2 key3', key1, key2, key3)
        video1.addEventListener('loadedmetadata', (event) => {
          console.log(event, 'event')
          count++
          _this.loadedVideoDom(idx, 'key1', video1, count)
        })
        const video2 = document.getElementById(key2)
        video2.addEventListener('loadedmetadata', (event) => {
          console.log(event, 'event')
          count++
          _this.loadedVideoDom(idx, 'key2', video2, count)
        })
        const video3 = document.getElementById(key3)
        video3.addEventListener('loadedmetadata', (event) => {
          console.log(event, 'event')
          count++
          _this.loadedVideoDom(idx, 'key3', video3, count)
        })
      })
    },
    // 加载回调
    loadedVideoDom(index, key, video, loedcount) {
      console.log(loedcount, 'loedcount')
      this.videoElements[index][key] = video
      // if (loedcount === this.videoElements.length * 3) {
      // console.log('执行完毕---', this.videoElements)
      // this.isLoadedAllVideo = true
      // }
      var isfinish = true
      for (let i = 0; i < this.videoElements.length; i++) {
        const ele = this.videoElements[i]
        if (!(ele.key1 && ele.key2 && ele.key3)) {
          isfinish = false
          break
        }
      }
      if (isfinish) {
        console.log('执行完毕---', this.videoElements)
        this.isLoadedAllVideo = true
      }
    },
    initWebAudioAPI() {
      this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
      this.analyser = this.audioContext.createAnalyser()
      this.source = this.audioContext.createMediaElementSource(this.$refs.audioDiv)
      this.source.connect(this.analyser)
      this.analyser.connect(this.audioContext.destination)
      this.analyser.fftSize = 2048
      this.dataArray = new Uint8Array(this.analyser.frequencyBinCount)
    },

    // 视频相关
    // videoRelatedFn() {
    //   this.audioContext = new (window.AudioContext || window.webkitAudioContext)()
    //   console.log(this.audioContext, '----this.audioContext----')
    //   this.analyser = this.audioContext.createAnalyser()
    //   console.log(this.analyser, '----this.analyser----')
    // },
    detectPhoneme() {
      this.analyser.getByteFrequencyData(this.dataArray)
      const averageFrequency = this.dataArray.reduce((a, b) => a + b) / this.dataArray.length
      console.log(averageFrequency, '--averageFrequency')
      // 简单的音量阈值判断
      if (averageFrequency > 10) {
        this.video_show = false
        this.touchable = false
        switch (this.swipeIndex2) {
          case 0:
            if (this.firstAiVideo) {
              this.videoElements[0].key3.play()
            } else {
              this.videoElements[0].key2.play()
            }
            break
          case 1:
            if (this.firstAiVideo) {
              this.videoElements[1].key3.play()
            } else {
              this.videoElements[1].key2.play()
            }
            break
          // case 2:
          //   if (this.firstAiVideo) {
          //     this.videoElements[2].key3.play()
          //   } else {
          //     this.videoElements[2].key2.play()
          //   }
          //   break
          default:
            break
        }
        return 'b'
      } else if (averageFrequency == 0) {
        this.touchable = true
        switch (this.swipeIndex2) {
          case 0:
            // this.videoElements[0].key1.pause()
            this.videoElements[0].key2.pause()
            this.videoElements[0].key3.pause()
            break
          case 1:
            // this.videoElements[1].key1.pause()
            this.videoElements[1].key2.pause()
            this.videoElements[1].key3.pause()
            break
          // case 2:
          //   // this.videoElements[1].key1.pause()
          //   this.videoElements[2].key2.pause()
          //   this.videoElements[2].key3.pause()
          //   break
          default:
            break
        }
        return
      } else {
        this.touchable = false
        switch (this.swipeIndex2) {
          case 0:
            if (this.firstAiVideo) {
              this.videoElements[0].key3.play()
            } else {
              this.videoElements[0].key2.play()
            }
            break
          case 1:
            if (this.firstAiVideo) {
              this.videoElements[1].key3.play()
            } else {
              this.videoElements[1].key2.play()
            }
            break
          // case 2:
          //   if (this.firstAiVideo) {
          //     this.videoElements[2].key3.play()
          //   } else {
          //     this.videoElements[2].key2.play()
          //   }
          //   break
          default:
            break
        }
        this.video_show = true
        return 'a'
      }
    },
    switchLipVideo(phoneme) {
      console.log(phoneme, '---phoneme')
      // if (phoneme !== this.currentPhoneme) {
      //   this.$refs[`video${this.currentPhoneme.toUpperCase()}`].classList.remove('active')
      //   this.$refs[`video${phoneme.toUpperCase()}`].currentTime = this.$refs[`video${this.currentPhoneme.toUpperCase()}`].currentTime
      //   this.$refs[`video${phoneme.toUpperCase()}`].classList.add('active')
      //   this.currentPhoneme = phoneme
      // }
    },
    // 获取token
    getAiEnToken() {
      getAiEnToken().then((res) => {
        if (res.errNo == 0) {
          var data = JSON.parse(res.result)
          this.token = data.token
          this.appkey = data.appkey
          this.headerTemplate.appkey = data.appkey
        }
      })
    },
    // 轮播图监听事件
    onChange(index) {
      console.log(index, 'index')
      if (index == 0) {
        this.voice_type = 'lydia'
      } else if (index == 1) {
        this.voice_type = 'aishuo'
      }
      console.log(this.voice_type, 'this.voice_type')
      this.swipeIndex2 = index
      // 切换男生、女生 第一句介绍语
      this.setFirstIntrol(this.swipeIndex2)
    },
    // 切换男生、女生 第一句介绍语
    setFirstIntrol(index) {
      if (index == 0) {
        // 女生
        this.list[0].a_cn = this.introl_woman_cn
        this.list[0].a_en = this.introl_woman_en
      } else {
        // 男生
        this.list[0].a_cn = this.introl_man_cn
        this.list[0].a_en = this.introl_man_en
      }
    },
    // 获取麦克风权限
    getMicrophoneAccess() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
            // 获取麦克风成功，可以在这里处理流，例如播放等
            console.log('麦克风访问成功')
            // // 播放音频
            // const audio = new Audio();
            // audio.srcObject = stream;
            // audio.play();
          })
          .catch(error => {
            console.error('麦克风访问失败：', error)
          })
      } else {
        alert('您的浏览器不支持获取麦克风')
      }
    },
    // // 播放
    // playSound(item) {
    //   this.list.forEach(res => {
    //     res.isPlay = false
    //   })
    //   item.isPlay = !item.isPlay
    // },
    // // 暂停
    // stopSound(item) {
    //   console.log(item,'item')
    //   item.isPlay = !item.isPlay
    // },

    // 按下说话-start
    touchStartRecordBtn(e) {
      e.preventDefault()
      this.longpressTimer = setTimeout(() => {
        this.isLongPress = true
      }, 200)
    },
    // 按下说话-end
    touchEndRecordBtn(e) {
      e.preventDefault()
      clearInterval(this.longpressTimer)
      this.longpressTimer = null
      if (!this.isLongPress) {
        // 非长按 录音
        this.startRecordAudioBtn()
      }
      this.isLongPress = false
    },
    // 按下说话
    startRecordAudioBtn() {
      if (!this.touchable) {
        return
      }
      // const limit = this.lougout_use_limit * 2 + this.introl_def_count
      // const counts = this.list.length
      // if (WechatState.isWechatMiniprogram) {
      //   if (!this.isLogin && counts >= limit) {
      //     console.log('需要登录')
      //     this.showWxMiniprogramLogin()
      //     return
      //   }
      // }

      this.closeAllAudio(this.list[this.myAutoIndex]) // 关闭所有的播放器
      // this.list[this.myAutoIndex].isPlay = false
      this.touchable = false

      this.bufferend = false
      this.ailoading = true
      this.websocket_audio2txt_result_msg = ''
      this.getMicrophoneAccess()
      this.initWebSocket()
    },
    // 开始录音
    startRecordAudio() {
      Recorder.getPermission().then(
        () => {
          console.log('开始录音')
          this.recorder.start().then(() => {
            // 开始录音
            this.speakIng = true
          }, (error) => {
            console.log(error, `出错了`)
          })
          var that = this
          that.recorder.onprogress = function(params) {
            that.timeSpan = Math.floor(params.duration) + 's'
            // console.log('--------------START---------------')
            // console.log('录音时长(秒)', params.duration)
            // console.log('录音大小(字节)', params.fileSize)
            // console.log('录音音量百分比(%)', params.vol)
            // console.log('当前录音的总数据)', params.data)
            // console.log('--------------END---------------')
          }
        },
        (error) => {
          this.$message({
            message: '请先允许该网页使用麦克风',
            type: 'info'
          })
          console.log(`${error.name} : ${error.message}`)
        }
      )
    },
    // 停止录音
    stopRecordAudio() {
      console.log('执行了2')
      this.recorder.stop() // 停止录音
      clearTimeout(this.interval)
      // 停止websocket
      this.websocket.close()
      this.websocket = null
      this.speakIng = false
      this.ailoading = false
      this.touchable = true
      this.websocket_audio2txt_result_msg = ''
      this.bufferend = false
    },
    initWebSocket() {
      console.log('初始化weosocket')

      // 初始化参数
      this.websocket_audio2txt_complete_b = false
      this.websocket_audio2txt_time = 0

      // 检测如果未关闭、则先关闭在重连
      if (this.websocket !== null) {
        this.websocket.close()
        this.websocket = null
      }

      // ali的websocket地址
      const wsuri = `wss://nls-gateway.aliyuncs.com/ws/v1?token=${this.token}`

      // 连接wss服务端
      this.websocket = new WebSocket(wsuri)
      console.log(this.websocket, 'this.websocket')
      // 指定回调函数
      this.websocket.onopen = this.websocketOnOpen
      this.websocket.onmessage = this.websocketOnMessage
      this.websocket.onerror = this.websocketOnError
      this.websocket.onclose = this.websocketClose
    },
    websocketOnOpen() {
      console.log('向 websocket 发送 链接请求')
      // 生成新的任务id
      this.websocket_task_id = this.getRandomStrNum()
      // 生成ali的请求参数message_id
      const message_id = this.getRandomStrNum()
      const actions = {
        'header': {
          'namespace': 'SpeechTranscriber', // 固定值
          'name': 'StartTranscription', // 发送请求的名称，固定值
          'appkey': this.appkey, // appkey
          'message_id': message_id, // 消息id
          'task_id': this.websocket_task_id // 任务id
        },
        'payload': {
          'format': 'PCM', // 音频编码格式，默认是PCM（无压缩的PCM文件或WAV文件），16bit采样位数的单声道。
          'sample_rate': 16000, // 需要与录音采样率一致、默认是16000，单位是Hz。
          'enable_intermediate_result': true, // 是否返回中间识别结果，默认是false。
          'enable_punctuation_prediction': true, // 是否在后处理中添加标点，默认是false。
          'enable_inverse_text_normalization': true, // 是否在后处理中执行数字转写，默认是false。
          'max_sentence_silence': 500//	语音断句检测阈值，静音时长超过该阈值会被认为断句，参数范围200ms～2000ms，默认值800ms。
        }
      }

      // 发送请求
      this.websocketSend(JSON.stringify(actions))
    },

    websocketSend(data) {
      // console.log(this.websocket.readyState, 'websocket 数据发送', data)
      // 判断是否连接成功,连接成功再发送数据过去
      if (this.websocket.readyState === 1) {
        this.websocket.send(data)
      } else {
        console.log('websock未连接-------------------')
      }
    },
    websocketOnMessage(e) {
      // 接受ali 语音返回的数据
      const ret = JSON.parse(e.data)
      // 判断返回的数据类型
      if (ret.header.name === 'TranscriptionResultChanged') {
        // 数据在收集中 一句话的中间结果
        console.log('数据在收集中')
        // 实时获取语音转文本的结果
        // this.ingText(ret.payload.result)
      } else if (ret.header.name === 'SentenceBegin') {
        // 一句话开始后，就可以启动录音了
        console.log('检测到了一句话的开始')
        // 添加一个新的p标签、用于显示中间变化状态
        // var span = document.createElement('p')
        // span.innerText = ''
        // statusDiv.appendChild(span)
      } else if (ret.header.name === 'TranscriptionStarted') {
        console.log('服务端已经准备好了进行识别，客户端可以发送音频数据了')
        // 获取音频信息，定时获取并发送
        this.interval = setInterval(() => {
          this.getPCMAndSend()
        }, 100)
        setTimeout(() => {
          this.startRecordAudio()
        }, 1000)
      } else if (ret.header.name === 'SentenceEnd') {
        console.log('数据接收结束', ret)
        this.endText(ret.payload.result)
      } else if (ret.header.name === 'TranscriptionCompleted') {
        console.log('服务端已停止了语音转写', ret)
      }
    },
    websocketOnError(e) {
      console.log('连接建立失败重连')
      // initWebSocket();
    },
    websocketClose(e) {
      console.log('websocketClose断开连接', e)
    },
    websocketSendStop() {
      console.log('向  websocket 发送 Stop指令')
      const message_id = this.getRandomStrNum()
      // actions 是首次连接需要的参数,可自行看阿里云文档
      const actions = {
        'header': {
          'message_id': message_id,
          'task_id': this.websocket_task_id,
          'namespace': 'SpeechTranscriber',
          'name': 'StopTranscription',
          'appkey': this.appkey
        }
      }

      // 发送结束指令
      this.websocketSend(JSON.stringify(actions))
    },
    // ingText(text) {
    //   // const ps = statusDiv.querySelectorAll('p')
    //   // // 更新中间变化状态
    //   // const lastP = ps[ps.length - 1]
    //   // lastP.innerText = text
    //   this.ssText = text
    //   console.log(text)
    // },
    endText(text) {
      // const ps = statusDiv.querySelectorAll('p')
      // // 更新最后的识别结果
      // const lastP = ps[ps.length - 1]
      // lastP.innerText = text

      console.log(text)
      // 获取全文
      this.websocket_audio2txt_result_msg += text
      console.log('第一个websocket结束:', this.websocket_audio2txt_result_msg)

      // todo 可以进行匹配语音匹配了
    },
    getPCMBlobYp() {
      // 合并所有片段
      const combined = new Blob(this.recordedChunks, { type: 'audio/wav' })
      // 现在combined就是整个录音的Blob，可以进行下载、上传等操作
      console.log('Combined Blob created:', combined)
      // console.log(this.recorder, 'this.recorder')
      // var pcmBlob = this.recorder.getPCMBlob()
      // console.log(pcmBlob, 'pcmBlob')
      var formData = new FormData()
      // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
      const newbolb = new Blob([combined], { type: 'audio/pcm' })
      console.log(newbolb, 'newbolb')
      const fileOfBlob = new File([newbolb], new Date().getTime() + '.pcm')
      console.log(fileOfBlob, '-----------fileOfBlob')
      formData.append('audioFile', fileOfBlob)
      console.log(formData, '-----------formData')
      const axios = require('axios')
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      axios.post('https://m.openwww.zhulong.com/ucenter/prod-api/api/ai/aiEnUpload', formData, config)
        .then(response => {
          console.log(response.data, 'console.log(data)')
          var res = response.data
          this.meAudiomp3 = res.result.q_wav
          var audioIndex = this.list.findIndex(i => i.q_source == this.websocket_audio2txt_result_msg)
          if (audioIndex > -1) {
            this.list[audioIndex].wav = this.meAudiomp3
          }
          console.log(this.meAudiomp3, 'this.meAudiomp3')
          this.recordedChunks = []
        }).catch(() => {
          this.recordedChunks = []
        })
    },
    getPCMAndSend() {
      // 获取音频信息
      const NextData = this.recorder.getNextData()
      if (NextData) {
        this.recordedChunks.push(NextData)
        // 可以在这里处理或预览每个片段
      }
      const blob = new Blob([NextData])
      const blob_size = blob.size
      console.log('获取音频信息，并发送,blob_size:' + blob_size, blob)

      // ali最大支持3200字节的音频
      const max_blob_size = 3200// 支持1600 或3200
      let my_num = blob_size / max_blob_size
      my_num = my_num + 1
      // 切分音频发送
      for (let i = 0; i < my_num; i++) {
        var end_index_blob = max_blob_size * (i + 1)
        // 判断结束时候的分界
        if (end_index_blob > blob_size) {
          end_index_blob = blob_size
        }
        // 切分音频
        var blob2 = blob.slice(i * max_blob_size, end_index_blob)
        // 生成新的blob
        const newbolb = new Blob([blob2], { type: 'audio/pcm' })
        // 发送
        this.websocketSend(newbolb)
      }
    },

    getRandomStrNum() {
      var s = []
      var hexDigits = '0123456789abcdef'
      for (var i = 0; i < 32; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
      }
      s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23]

      var uuid = s.join('')
      return uuid
    },

    // 句子优化
    aiTextClick(item) {
      console.log(item, 'item')
      item.ai_text = !item.ai_text
      this.$forceUpdate()
    },
    // AI优化后的录音 右侧的，相当于是提问
    aiAudioClick(item) {
      this.list[this.myAutoIndex].isPlay = false
      console.log(item, 'item')
      var msg = item.q_en
      if (this.lang_type == 'zh') {
        msg = item.q_source
      }
      const info = {
        msg: msg,
        voice: this.voice_type,
        rate: this.speach_rate
      }
      speechSynthesizer(info).then(res => {
        if (res.errNo == 0) {
          this.myAuto.src = res.result.url
          this.myAuto.play().then(() => {
            // 说话视频
            this.video_show = false
            this.touchable = false
            this.csh = false
            if (this.firstAiVideo) {
              this.videoElements[0].key3.play()
              this.videoElements[1].key3.play()
            } else {
              this.videoElements[0].key2.play()
              this.videoElements[1].key2.play()
            }
            // this.videoElements[2].key2.play()
          })
          this.touchable = false
          this.myAuto.addEventListener('ended', () => {
            // 结束之后的逻辑
            this.video_show = true
            this.touchable = true
            this.videoElements[0].key1.play()
            this.videoElements[1].key1.play()
            // this.videoElements[2].key1.play()
          })
          this.myAuto.addEventListener('error', () => {
            // 结束之后的逻辑
            this.video_show = true
            this.touchable = true
            this.videoElements[0].key1.play()
            this.videoElements[1].key1.play()
            // this.videoElements[2].key1.play()
          })
        }
      })
    },
    // 点击 播放MP3
    autoPlay(item, index) {
      console.log(item, 'item')
      // if (item.isPlay) {
      //   console.log('当前item在播放中')
      // } else {
      //   console.log('当前item不不不在播放中')
      // }
      if (!this.touchable && !item.isPlay) { // 需要判断是否在播放，在播放还得执行关闭
        return
      }

      this.touchable = false
      if ((this.myAutoIndex === 0 || this.myAutoIndex > 0) && this.myAutoIndex != index) {
        this.list[this.myAutoIndex].isPlay = false
      }
      this.stopAudio()
      this.myAuto.pause()
      this.myAutoIndex = index
      item.isPlay = !item.isPlay
      console.log(item.isPlay, '=======item.isPlay')
      if (item.isPlay) {
        if (item.type == 1) {
          this.initPromise = null
          this.connectPromise = null
          this.mediaPromise = null
          this.sourceBuffer = null
          this.bufferend = false

          console.log(this.isIOS, item, '--------this.isIOS')
          this.audio_ios_mp3(item)
        } else {
          console.log(item.wav, 'item.wav')
          this.myAuto.src = item.wav
          this.myAuto.play()
          this.palyEnd(item)
        }
      } else {
        this.closeAllAudio(item)
      }
    },
    // 关闭所有的播放器
    closeAllAudio(item) {
      console.log(item, 'item')
      this.touchable = true
      this.stopAudio()
      this.palyEnd(item)
      this.myAuto.pause()
      // 视频播放结束
      this.video_show = true
      // this.videoElements[0].key1.pause()
      this.videoElements[0].key2.pause()
      this.videoElements[0].key3.pause()
      // this.videoElements[1].key1.pause()
      this.videoElements[1].key2.pause()
      this.videoElements[1].key3.pause()
      // this.videoElements[2].key2.pause()
      // this.videoElements[2].key3.pause()
      // document.getElementById('video-1-1').pause()
      // document.getElementById('video-1-3').pause()
      // document.getElementById('video-2-1').pause()
      // document.getElementById('video-2-3').pause()
      if (this.interval2) {
        clearTimeout(this.interval2)
        this.reset()
      }
    },
    // callMethodWithDelay(arr, index = 0) {
    //   if (index >= arr.length) {
    //       this.sendSSE('', true)
    //     return // 当数组遍历完后停止
    //   }
    //   this.sendSSE(arr[index])
    //   // 设置下一次调用的延迟，递归调用自身，传入下一个索引
    //   setTimeout(() => this.callMethodWithDelay(arr, index + 1), 500)
    // },
    palyEnd(item) {
      this.myAuto.addEventListener('ended', () => {
        if (item) {
          item.isPlay = false
        }
        this.touchable = true
        this.video_show = true
      })
      this.myAuto.addEventListener('error', () => {
        if (item) {
          item.isPlay = false
        }
        this.touchable = true
        this.video_show = true
      })
    },
    send() {
      console.log('执行了1')
      this.recorder.stop()// 停止录音

      this.speakIng = false
      // this.loading = true
      this.$nextTick(() => {
        this.scorellToBottom()
      })
      setTimeout(() => {
        clearTimeout(this.interval)
        // 停止websocket
        this.websocket.close()
        this.websocket = null
        // 翻译回复接口
        // 自己的录音
        this.meAudiomp3 = ''
        this.getPCMBlobYp()
        this.chatAiEnglish()
      }, 2000)
      // clearTimeout(this.interval)
      // // 停止websocket
      // this.websocket.close()
      // this.websocket = null
      // // 翻译回复接口
      // this.chatAiEnglish()
      // var timeed = Number(this.recorder.duration.toFixed(2))
      // console.log(timeed, 'timeed')
    },
    chatAiEnglish() {
      console.log('执行了')
      console.log(this.websocket_audio2txt_result_msg, 'this.websocket_audio2txt_result_msg')
      if (this.websocket_audio2txt_result_msg != this.introl_man_en && this.websocket_audio2txt_result_msg != this.introl_woman_en) {
        this.firstAiVideo = false
      } else {
        this.firstAiVideo = true
      }
      var _this = this
      this.eventSource = new EventSource(`https://m.zhulong.com/ucenter/prod-api/api/aiv2/chatAiEnglish?message=${encodeURIComponent(this.websocket_audio2txt_result_msg)}&lang=${this.lang_type}&lg=${this.lg_type}&hisrorymsg=1`)
      this.eventSource.onmessage = (event) => {
        const s = JSON.parse(event.data)
        console.log(s.data, 's.data')
        if (JSON.stringify(s.data) !== '{}') {
          _this.ailoading = false
          console.log(s.data, 's.data')
          // 获取倒数据
          _this.getChatAiSuccessData(s, false)
        } else {
          // 添加数组数据
          _this.addInitObjToList(false)
          this.myAutoIndex = this.list.length - 1
        }
      }
      this.eventSource.onerror = () => {
        console.log('SSE error')
        if (this.isIOS) {
          this.audio_ios_mp3(this.list[this.list.length - 1], true)
        } else {
          this.sendSSE('', true)
        }

        this.take = ''
        this.eventSource.close()
      }
    },
    // 获取历史记录
    getHistoryDatas() {
      this.is_getted_history = false
      gethistorymsg_wav().then(res => {
        if (res.errNo == 0) {
          const datas = res.result || []
          if (datas.length > 0) {
            datas.forEach(ele => {
              // 模拟获取成功数据
              var ele_data = ele.sysmessage || ''
              var data = JSON.parse(ele_data)
              var s_data = { data: data }
              // 创建数据倒list
              this.addInitObjToList(true)
              this.getChatAiSuccessData(s_data, true)
            })
          }
        }
        this.is_getted_history = true
        this.checkAllload()
      }).catch(() => {
        this.is_getted_history = true
        this.checkAllload()
      })
    },
    // 向数组添加数据
    addInitObjToList(history) {
      var myMp3 = this.meAudiomp3
      console.log(myMp3, '我自己的录音')
      this.list.push(
        {
          type: 2,
          isPlay: false,
          a_cn: '',
          a_en: '',
          q_cn: '',
          q_source: '',
          q_en: '',
          q_ln: '',
          is_history: history,
          wav: myMp3 || ''
        },
        {
          type: 1,
          isPlay: false,
          a_cn: '',
          a_en: '',
          q_cn: '',
          q_source: '',
          q_en: '',
          wav: '',
          is_history: history,
          ai_text: false
        }
      )
    },
    // 语音转换接口成功调取
    getChatAiSuccessData(s, history) {
      // 获取配置语速
      const is_rate = s.data.is_rate ?? ''
      if (s.data.rate != undefined && s.data.rate != null && s.data.rate != '-' && is_rate == 'true') {
        const rate = s.data.rate
        this.speach_rate = rate
        // console.log(rate, 'rateraterateraterate')
      }

      // 英语等级配置
      const is_lg = s.data.is_lg ?? ''
      if (s.data.lg && s.data.lg != undefined && s.data.lg != null && s.data.lg != '-' && is_lg == 'yes') {
        const lg = s.data.lg
        this.lg_type = lg
        console.log('获取到英语等级', lg)
      }

      // 中英配置
      const is_sl = s.data.is_sl ?? ''
      if (s.data.sl && s.data.sl != undefined && s.data.sl != null && s.data.sl != '-' && is_sl == 'yes') {
        const sl = s.data.sl
        this.lang_type = sl
        console.log('获取到中英配置', sl)
      }

      if (s.data.q_cn) {
        this.list[this.list.length - 2].q_cn = s.data.q_cn
      }
      if (s.data.q_en) {
        this.list[this.list.length - 2].q_en = s.data.q_en
      }
      // 问题语言：zh、en
      if (s.data.q_ln) {
        this.list[this.list.length - 2].q_ln = s.data.q_ln
      }

      if (s.data.q_source) {
        this.list[this.list.length - 2].q_source = s.data.q_source
      }

      if (s.data.a_cn) {
        this.list[this.list.length - 1].a_cn = s.data.a_cn
      }

      if (history) {
        if (s.data.a_en) {
          this.list[this.list.length - 1].a_en = s.data.a_en
        }
      } else {
        if (s.data.a_en) {
          if (this.take === '') {
            if (!this.isIOS) {
              this.sendSSE(s.data.a_en)
            }
            this.take = s.data.a_en
          } else if (s.data.a_en === this.take) {
            console.log('执行了最终结果')
            this.list[this.list.length - 1].a_en = s.data.a_en
          } else {
            if (!this.isIOS) {
              this.sendSSE(s.data.a_en.replace(this.take, ''))
            }
            this.take = s.data.a_en
            this.list[this.list.length - 1].a_en = s.data.a_en
          }
        } else {
          console.log('No data:', s)
        }
      }

      this.$nextTick(() => {
        this.scorellToBottom()
      })
    },
    // 初始化
    init() {
      this.baseUrl = 'wss://nls-gateway.aliyuncs.com/ws/v1'
      this.url = new URL(this.baseUrl)
      this.url.searchParams.set('appKey', this.appkey)
      this.url.searchParams.set('token', this.token)
      this.headerTemplate.appkey = this.appkey
      console.log(this.url, 'this.url')
    },
    setupAudioPlayer() {
      let playing = false
      let ended = false
      console.log('zhixingle1')
      document.getElementById('audioDiv').addEventListener('play', () => {
        playing = true
        ended = false
      })
      console.log('zhixingle2')

      document.getElementById('audioDiv').addEventListener('ended', () => {
        playing = false
        ended = true
        document.dispatchEvent(this.played)
        console.log('自动关关闭音频1')
      })

      document.getElementById('audioDiv').addEventListener('error', (e) => {
        console.error('Audio error:', e)
      })

      this.play = (source) => {
        return new Promise((resolve) => {
          document.getElementById('audioDiv').src = URL.createObjectURL(source)
          console.log(document.getElementById('audioDiv').src, 'audioDiv.src')
          document.getElementById('audioDiv').play().then(resolve).catch((error) => {
            console.error('Error playing audio:', error)
          })
        })
      }

      this.stopAudio = () => {
        document.getElementById('audioDiv').pause()
        document.getElementById('audioDiv').currentTime = 0
        playing = false
        ended = true
      }
    },
    open() {
      // if (this.websocket2 !== null) {
      //   this.websocket2.onclose()
      //   this.websocket2 = null
      // }
      console.log('zhixinglema')
      return new Promise((resolve, reject) => {
        this.headerTemplate.task_id = this.nanoid(32)
        console.log(this.url, '-------+++++++this.url')
        this.websocket2 = new WebSocket(this.url)
        this.websocket2.onopen = () => {
          const request = {
            header: { ...this.headerTemplate, name: 'StartSynthesis', message_id: this.nanoid() },
            payload: {
              voice: this.voice_type,
              format: 'mp3',
              sample_rate: 16000
            }
          }
          console.log(request, '----------request')
          this.websocket2.send(JSON.stringify(request))
        }

        this.websocket2.onmessage = (event) => {
          if (typeof event.data === 'string') {
            const response = JSON.parse(event.data)
            const { name, status, status_message } = response.header
            console.log(response, '----------response')
            if (status !== 20000000) {
              reject(new Error(status_message))
            } else if (name === 'SynthesisStarted') {
              console.log('Synthesis started')
              resolve()
            } else if (name === 'SentenceSynthesis') {
              console.log('SentenceSynthesis')
              resolve()
            } else if (name === 'SynthesisCompleted') {
              this.bufferend = true
              console.log('SynthesisCompleted')
              //	this.mediaSource.endOfStream();
              // this.audio.dispatchEvent(new Event('ended'));
            }
          } else {
            this.blobQueue.push(event.data)
            this.blobCache.push(event.data)
            this.fetchBuffer()
          }
          // console.log(event, 'event')
          // // // 假设接收到的是Blob数据
          // const binaryData = new Blob([event.data], { type: 'audio/mp3' })// 这里应该是实际的二进制数据
          // var audioUrl = URL.createObjectURL(binaryData)
          // console.log(audioUrl, 'audioUrl')
          // var blob = new Blob([event.data], { type: 'audio/mp3' })

          // // 创建一个用于下载的链接
          // var link = document.createElement('a')
          // var url = URL.createObjectURL(blob)
          // link.href = url
          // console.log(link.href, 'link.href')
          // link.download = 'audio.mp3'

          // // 触发下载
          // link.click()

          // // 清理
          // URL.revokeObjectURL(url)
        }

        this.websocket2.onerror = (event) => {
          console.error('WebSocket2 error:', event)
          reject(event)
        }
        this.websocket2.onclose = (event) => {
          // this.saveAudio()
          // console.log(this.list, '------带音频的list')
          // this.websocket2 = null
          // reject(event)
          try {
            // 保存音频逻辑
            if (this.blobCache.length > 0) {
              console.log('Audio saved after WebSocket closed.')
              console.log('------播放结束2------')
              // this.list[this.myAutoIndex].isPlay = false
              // // 初始化
              // this.initPromise = null
              // this.connectPromise = null
              // this.mediaPromise = null
              // this.sourceBuffer = null
            } else {
              console.log('No audio data to save.')
            }
            // this.initPromise = null
            // this.connectPromise = null
            // this.mediaPromise = null
            // this.sourceBuffer = null
            // this.list[this.myAutoIndex].isPlay = false
            // this.connectPromise = null
            // this.mediaPromise = null
            // // 清理或重置相关状态
            // this.blobCache = [] // 清空缓存的Blob数据
            // this.blobQueue = [] // 如果也需要清空队列
            // this.websocket2 = null // 确保实例被释放，避免内存泄漏

            // 根据需要通知用户或执行下一步操作
            console.log('WebSocket connection closed.', event)

            // 如果在某些情况下你不希望reject，可以考虑根据event.code来决定
            // WebSocket关闭代码通常为1000表示正常关闭，其他代码可能表示异常
            if (event.code && event.code !== 1000) {
              reject(new Error(`WebSocket closed with code ${event.code}`))
            }
          } catch (error) {
            console.error('Error during WebSocket close handling:', error)
            reject(error)
          }
        }
      })
    },
    // saveAudio() {
    //   const mergedBlob = new Blob(this.blobCache, { type: 'audio/mpeg' })
    //   const playableUrl = URL.createObjectURL(mergedBlob)
    //   this.list[this.list.length - 1].wav = playableUrl
    // },
    async fetchBuffer() {
      if (this.lock || !this.sourceBuffer || !this.blobQueue.length || this.sourceBuffer.updating) {
        return
      }
      this.lock = true
      const blob = this.blobQueue.shift()
      if (blob) {
        const buffer = await blob.arrayBuffer()
        this.sourceBuffer.appendBuffer(buffer)
      }
      this.lock = false
    },
    async  _onUpdateEnd() {
      this.lock = false
      // Check the queue again in case new blobs were added.
      this.fetchBuffer()
      console.log(this.blobQueue.length, this.sourceBuffer.updating, this.bufferend, 'bbbbbbbb')
      // Optionally trigger an 'ended' event if the queue is empty and all updates are done.
      if (!this.blobQueue.length && !this.sourceBuffer.updating && this.bufferend) {
        this._onEnded()
      }
    },

    // Custom method to handle the 'ended' logic.
    _onEnded() {
      // Logic to handle the end of the buffering.
      // For example, dispatch an event or call a callback.
      try {
        if (this.mediaSource) {
          this.mediaSource.endOfStream()
        }
      } catch (error) {
        console.log(error, '报错信息提示----------')
      }
      // const event = new Event('ended');
      // this.sourceBuffer.dispatchEvent(event);
    },

    createStream() {
      return new Promise((resolve) => {
        this.blobQueue = []
        this.blobCache = []
        this.mediaSource = new MediaSource()
        this.mediaSource.addEventListener('sourceopen', () => {
          this.sourceBuffer = this.mediaSource.addSourceBuffer('audio/mpeg')
          this.sourceBuffer.addEventListener('updateend', () => this._onUpdateEnd())
          resolve()
        })
        console.log('----------第二步执行了')
        try {
          // this.source = this.context.createMediaElementSource(document.getElementById('audioDiv'))
          // console.log(this.source, 'this.source')
          // this.source.connect(this.analyser)
          // this.analyser.connect(this.context.destination)
          // console.log(this.analyser, 'this.analyser')
          this.audioContext.resume()
          // 添加其他口型视频的播放

          this.interval2 = setInterval(() => {
            this.detectPhoneme()
            // const phoneme = this.detectPhoneme()
            // console.log(phoneme, 'phoneme')
            // this.switchLipVideo(phoneme)
          }, 100) // 每100毫秒检测一次
        } catch (error) {
          console.log(error, 'error')
        }
        this.play(this.mediaSource).then(() => {
          console.log('------播放开始1------')

          // this.analyser.fftSize = 2048
          // this.dataArray = new Uint8Array(this.analyser.frequencyBinCount)
          // console.log(this.dataArray, 'this.dataArray')

          // setInterval(() => {
          //   console.log('-----执行了定时器')
          //   const phoneme = this.detectPhoneme()
          //   this.switchLipVideo(phoneme)
          // }, 100) // 每100毫秒检测一次

          this.touchable = false
          this.list[this.myAutoIndex].isPlay = true
          document.getElementById('audioDiv').addEventListener('ended', () => {
            this.touchable = true
            console.log('------播放结束1------')
            console.log('Playback ended')
            this.list[this.myAutoIndex].isPlay = false
            // 视频播放结束
            switch (this.swipeIndex2) {
              case 0:
                this.videoElements[0].key1.play()
                // this.videoElements[0].key1.pause()
                this.videoElements[0].key2.pause()
                this.videoElements[0].key3.pause()
                // document.getElementById('video-1-1').play()
                // document.getElementById('video-1-1').pause()
                // document.getElementById('video-1-3').pause()
                break
              case 1:
                this.videoElements[1].key1.play()
                // this.videoElements[1].key1.pause()
                this.videoElements[1].key2.pause()
                this.videoElements[1].key3.pause()
                // document.getElementById('video-2-1').play()
                // document.getElementById('video-2-1').pause()
                // document.getElementById('video-2-3').pause()
                break
                // case 2:
                //   this.videoElements[2].key1.play()
                //   // this.videoElements[1].key1.pause()
                //   this.videoElements[2].key2.pause()
                //   this.videoElements[2].key3.pause()
                //   // document.getElementById('video-2-1').play()
                //   // document.getElementById('video-2-1').pause()
                //   // document.getElementById('video-2-3').pause()
                //   break

              default:
                break
            }
            this.video_show = true
            clearTimeout(this.interval2)
            document.dispatchEvent(this.played)
            this.reset()
            // 主动关闭WebSocket连接
            this.websocket2.onclose()
          })
        })
      })
    },
    reset() {
      this.initPromise = null
      this.connectPromise = null
      this.mediaPromise = null
      this.sourceBuffer = null
    },
    // onPause() {
    //   this.list.forEach(res => {
    //     res.isPlay = false
    //   })
    // },
    async sendSSE(text, end = false) {
      console.log(text, 'text')
      if (!this.initPromise) {
        this.initPromise = this.init()
      }
      await this.initPromise
      if (!this.connectPromise) {
        console.log('进来了一')
        this.connectPromise = this.open()
      }
      await this.connectPromise
      console.log(end, 'end1')
      if (!this.mediaPromise) {
        console.log('进来了二')
        this.mediaPromise = this.createStream()
      }
      await this.mediaPromise
      console.log(end, 'end2')
      console.log(this.headerTemplate, 'this.headerTemplate')
      console.log(end, 'end3')
      if (end) {
        const request = {
          header: { ...this.headerTemplate, name: 'StopSynthesis', message_id: this.nanoid() }
        }
        console.log(request, '---------request')
        this.websocket2.send(JSON.stringify(request))
      } else {
        const request = {
          header: { ...this.headerTemplate, name: 'RunSynthesis', message_id: this.nanoid() },
          payload: { text }
        }
        console.log(request, '---------request')
        console.log(this.websocket2, 'this.websocket')
        this.websocket2.send(JSON.stringify(request))
      }
    },
    sendText1() {
      this.websocket_audio2txt_result_msg = '北京的天气怎么样'
      this.chatAiEnglish()
    },
    sendText2() {
      this.websocket_audio2txt_result_msg = `How's the weather in Beijing`
      this.chatAiEnglish()
    },
    nanoid(size = 32) {
      const alphabet = '0123456789abcdef'
      let id = ''
      for (let i = 0; i < size; i++) {
        id += alphabet[Math.floor(Math.random() * alphabet.length)]
      }
      return id
    },
    // 请求语音
    audio_ios_mp3(item, isPlay) {
      var msg = item.a_en
      var first_women_msg = this.introl_woman_en
      var first_men_msg = this.introl_man_en
      if (this.lang_type == 'zh') {
        msg = item.a_cn
        first_women_msg = this.introl_woman_cn
        first_men_msg = this.introl_man_cn
      }
      console.log('请求语音 cn = ', item.a_cn)
      console.log('请求语音 en = ', item.a_en)
      console.log('请求语音 lang_type = ', this.lang_type)
      const info = {
        msg: msg,
        voice: this.voice_type,
        rate: this.speach_rate
      }
      speechSynthesizer(info).then(res => {
        // document.getElementById('audioDivIOS').src = 'https://newoss.zhulong.com/ai/uploaded/3fc3b08c29a90ba439909e14fe67d400.mp3'
        // document.getElementById('audioDivIOS').play()
        // this.detectPhoneme()
        if (res.errNo == 0 && res.result) {
          this.list[this.myAutoIndex].wav = res.result.url
        }
      }).then(() => {
        console.log(this.list[this.myAutoIndex].wav, 'this.list[this.myAutoIndex].wav')
        this.myAuto.src = this.list[this.myAutoIndex].wav
        this.video_show = false
        this.touchable = false
        this.csh = false
        this.list[this.myAutoIndex].isPlay = true
        this.myAuto.play()
        // 重新播放视频
        this.videoElements[0].key2.currentTime = 0
        this.videoElements[0].key3.currentTime = 0
        this.videoElements[1].key2.currentTime = 0
        this.videoElements[1].key3.currentTime = 0
        // 说话视频
        if (msg == first_women_msg || msg == first_men_msg) {
          this.firstAiVideo = true
          console.log('sssssssssssssssssssss')
          this.videoElements[0].key3.play()
          this.videoElements[1].key3.play()
          // this.videoElements[2].key3.play()
        } else {
          console.log('bbbbbbbbbbbbbbbbbbbbb')
          this.firstAiVideo = false
          this.videoElements[0].key2.play()
          this.videoElements[1].key2.play()
          // this.videoElements[2].key2.play()
        }
        this.palyEnd(this.list[this.myAutoIndex])
      })
    },
    scorellToBottom() {
      window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
      })
    },
    // 头部收起
    sq() {
      this.headType = 2
    },
    // 头部展开
    zk() {
      this.swipeIndex = JSON.parse(JSON.stringify(this.swipeIndex2))
      this.headType = 1
    },
    // 判断是否是IOS系统
    checkIfIOS() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
    },

    // 生成头像
    getAvator(uid) {
      const domain = 'https://newoss.zhulong.com/tfs/'
      let num = uid
      const n = 9
      let len = num.toString().length
      while (len < n) {
        num = '0' + num
        len++
      }
      uid = num
      const dir1 = uid.substr(0, 3)
      const dir2 = uid.substr(3, 2)
      const dir3 = uid.substr(5, 2)
      const time = parseInt(new Date().getTime() / 1000) + ''
      const ava = domain + dir1 + '/' + dir2 + '/' + dir3 + '/' + uid.substr(-2) + '_avatar_big.jpg?t=' + time
      this.avator = ava
    },
    // 微信小程序的登录，方法调用之前已经判断是否是小程序环境
    showWxMiniprogramLogin() {
      if (WechatState.isWechatMiniprogram) {
        var url = ''
        url = `/pages/login/login?xcx_out=true`
        wxsdk.miniProgram.redirectTo({
          url: url
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
*{
  margin: 0;
  padding: 0;
}
.main{
  background: #F8F9FD;
  min-height: calc(100vh - 200px);
}
.van-popup{
  border-radius: 20px;
}
.popup_class{
  padding: 40px 0;
  border-radius: 44px;
  width: 440px;
  .title{
    text-align: center;
    font-size: 32px;
    color: #333;
  }
  .tips1 {
    margin-top: 10px;
    text-align: center;
    font-size: 26px;
    color: #666;
  }
  .tips2 {
    margin-top: 20px;
    text-align: center;
    font-size: 26px;
    color: #666;
  }
  .btn{
    margin: 0 auto;
    width: 150px;
    background: #ee2e2e;
    color: #fff;
    text-align: center;
    margin-top: 40px;
    /* padding: 5px 10px; */
    border-radius: 30px;
    height: 56px;
    line-height: 58px;
  }
  .progress {
    margin: 30px 0 0 30px;
    width: calc(100% - 60px);
  }
}
.header{
  width: 100%;
  position: fixed;
  z-index: 10;
  .header_box1{
    height: 476px;
    .gifImg{
      height: 476px;
      object-fit: cover;
    }
    .video_box {
      position: relative;
      height: 476px;
    }
    .video_item_box {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 476px;
      object-fit: cover;
      z-index: 100;
      background-color: #fff;
    }
    .zindex110 {
      z-index: 110;
    }
    img{
      width: 100%;
    }
    .btn{
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 0 40px 40px 0;
      font-size: 28px;
      span{
        margin-right: 6px;
      }
    }
    // video{
    //   width: 100%;
    //   height: 472px;
    //   object-fit: cover;
    //   // display: none;
    // }
    // .active{
    //   display: block;
    // }
  }
  .header_box2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    background: url('https://newoss.zhulong.com/forum/202405/16/18/1715854783222884.jpg') round;
    padding: 0 20px;
    .back{
      flex-shrink: 0;
      width: 88px;
      height: 38px;
    }
    img{
      width: 80px;
      height: 80px;
      display: block;
      border-radius: 100%;
    }
    .btn{
      font-size: 28px;
      span{
        margin-right: 6px;
      }
    }
  }
}
.paddingheight1{
  padding: 520px 40px 220px;
}
.paddingheight2{
  padding: 140px 40px 220px;
}
.content{
  z-index: 1;
  .pageloading{
    text-align: center;
  }
  .left,.right{
    display: flex;
    margin-bottom: 40px;
    position: relative;
    >img{
      width: 80px;
      height: 80px;
      object-fit: cover;
      // height: 100%;
      border-radius: 100%;
    }
    .text{
      max-width: calc(100% - 260px);
      padding: 30px 30px 30px;
      line-height: 46px;
      border-radius: 20px;
      .text1{
        font-size: 30px;
        color: #333;
      }
      .text2{
        font-size: 26px;
        color: #666;
      }
    }
    .caozuo{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0 0;
      color: #ee2e2e;
      line-height: 50px;
      .caozuo_left{
        margin-right: 40px;
        display: flex;
        align-items: center;
        // .line{
        //   margin: 0 6px;
        //   color: #666;
        // }
        // img{
        //   vertical-align: text-bottom;
        // }
        .cz_left_span {
          .cz_left_span1 {
            img {
              width: 40px;
              height: 40px;
              display: block;
            }
          }

        }
        .cz_left_span2 {
          margin-left: 15px;
          display: flex;
          align-items: center;
          img {
            width: 40px;
            height: 40px;
            display: block;
          }
        }
      }
      .sound1{
        float: right;
        width: 80px;
        height: 40px;
        border: 1px solid #666;
        border-radius: 20px;
        line-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 32px;
          height: 32px;
        }
      }
    }
    .sound2{
      float: right;
      width: 80px;
      height: 40px;
      border: 1px solid #666;
      border-radius: 20px;
      line-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 32px;
        height: 32px;
      }
    }
  }
  .left{
    >img{
      margin-right: 20px;
    }
    .text{
      background: #fff;
    }
    .text::after {
      position: absolute;
      top: 20px;
      left: 76px;
      content: '';
      border-width: 14px;
      border-style: solid;
      border-top-color: transparent;
      border-right-color: #fff;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
  }
  .right{
    flex-flow: row-reverse;
    >img{
      margin-left: 20px;
    }
    .text{
      background: #FFCFCF;
    }
    .text::after {
      position: absolute;
      top: 20px;
      right: 76px;
      content: '';
      border-width: 14px;
      border-style: solid;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: #FFCFCF;
    }
  }
}

.bottom{
  width: calc(100% - 80px);
  position: fixed;
  bottom: 0;
  background: #F8F9FD;
  padding: 50px 40px 80px;
  .btn_red{
    height: 80px;
    margin: 0 auto;
    border-radius: 40px;
    background: #ee2e2e;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 80px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }
  .btn_gray {
    height: 80px;
    margin: 0 auto;
    border-radius: 40px;
    background: #ddd;
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 80px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }
  .btn2{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .clear{
      background: #ffffff;
      width: 110px;
      height: 110px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 40px;
      }
    }
    .send{
      background: #ee2e2e;
      width: 110px;
      height: 110px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 50px;
      }
    }
    .timing{
      text-align: center;
      font-size: 32px;
      margin-bottom: 10px;
    }
    .loading {
      display: flex;
      align-items: center;
      height: 66px;
      justify-content: center;
    }
    .load {
      width: 10px;
      background-color: #EE2D31;
      animation: 1s move6 infinite;
      border-radius: 10px;
      margin: 5px;
    }
    .load:nth-child(1) {
      animation-delay: 0.2s;
    }
    .load:nth-child(2) {
      animation-delay: 0.4s;
    }
    .load:nth-child(3) {
      animation-delay: 0.6s;
    }
    @keyframes move6 {
      0% {
        height: 22px;
      }

      25% {
        height: 44px;
      }

      50% {
        height: 66px;
      }

      100% {
        height: 22px;
      }
    }
  }
}

.spinner{
  position: absolute;
  left: 50%;
  top: 38%;
  --d: 16px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  color: #fff;
  box-shadow: calc(1*var(--d))      calc(0*var(--d))     0 0,
          calc(0.707*var(--d))  calc(0.707*var(--d)) 0 0.1px,
          calc(0*var(--d))      calc(1*var(--d))     0 0.2px,
          calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 0.4px,
          calc(-1*var(--d))     calc(0*var(--d))     0 0.5px,
          calc(-0.707*var(--d)) calc(-0.707*var(--d))0 0.6px,
          calc(0*var(--d))      calc(-1*var(--d))    0 0.7px;
  animation: spinner-a90wxe 1s infinite steps(8);
}

@keyframes spinner-a90wxe {
  100% {
    transform: rotate(1turn);
  }
}
</style>
